import { useQuery } from '@tanstack/react-query';
import { Multicaller } from '~/lib/services/util/multicaller.service';
import { mapValues } from 'lodash';
import { BigNumber } from 'ethers';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '~/hooks/useWeb3React';
import { isSupportedChain } from './chains';
import { asViemAddress } from './address';

export type MulticallMappedBigNumberResult = Record<string, BigNumber>;

interface UseMultiCallInput {
  abi: any;
  calls: { address: string; functionName: string; args?: any[] }[];
  enabled?: boolean;
}

export function useMultiCall<T>({ abi, calls, enabled = true }: UseMultiCallInput) {
  const { chainId, provider } = useWeb3React();

  return useQuery({
    queryKey: ['useMultiCall', JSON.stringify(abi), JSON.stringify(calls)],
    queryFn: async () => {
      if (!chainId || !isSupportedChain(chainId)) return [];

      calls = calls.filter((c) => c !== undefined);
      if (calls.length === 0) {
        return [];
      }

      const contracts = calls.map((c) => {
        return {
          address: asViemAddress(c.address),
          functionName: c.functionName,
          abi: abi,
          args: c.args,
        };
      });

      // @ts-ignore
      const response = await provider.multicall({
        contracts,
      });

      return response as { error?: Error; result: T; status: 'success' | 'failure' }[];
    },
    enabled,
  });
}

export async function mapBigNumberResult(multicaller: Multicaller, decimlas = 18) {
  const result = await multicaller.execute<MulticallMappedBigNumberResult>();
  return mapValues(result, (bn) => formatUnits(bn, decimlas));
}
