import { NetworkConfig } from './network-config-type';

export const ArbitrumNetworkConfig: NetworkConfig = {
  appName: 'ArbiDex',
  chainId: 42161,
  networkName: 'Arbitrum',
  networkShortName: 'Arbitrum',
  etherscanName: 'Arbiscan',
  etherscanUrl: 'https://arbiscan.io/',
  dexIcon: '',
  dexIconInRepo: '/logo/arx.png',
  testnet: false,
  isV2Supported: true,
  ens: {
    hasWebNameSupport: false,
    nameServiceId: null,
  },
  iconUrl: 'https://assets.coingecko.com/coins/images/16547/standard/arb.jpg?1721358242',
  eth: {
    name: 'ETH',
    address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    symbol: 'ETH',
    decimals: 18,
    iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
  },
  wethAddress: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  wethAddressFormatted: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
  tokens: {
    wbtc: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
    arb: '0x912CE59144191C1204E64559FE8253a0e49E6548',
  },
  coingecko: {
    nativeAssetId: 'ether',
    platformId: 'ethereum',
  },
  rpcUrl: 'https://arb1.arbitrum.io/rpc',

  multicall: '0x98cb0A82D20414d57ba567e874f92E150e836Cd4',
  contracts: {
    v2Router: '0x7238FB45146BD8FcB2c463Dc119A53494be57Aac',
    v2Zap: '0x97C397b23836EbAA1f5602894cAA469537E85f21',
    uniMulticall: '0x3647c51266cc1610d1318edbdc1129da75db75c5',
    v3: {
      factory: '0xa3792B3678b61001839c404ffcD20EF103473f68', // "PoolDeployer" from defiedge setup
      // factory: '0x855F2c70cf5cb1D56C15ed309a4DfEfb88ED909E',
      // nonFungibleManager: '0x763c2e4393Ed80480D735193D57f31692Fe4504e',
      nonFungibleManager: '0x81F2c375AEDbdF02f11c1Ae125e2f51Efa777cEa',
      tickLens: '0xf7fe5C645e6Bd1f410447e3266A02E82c3aeefD9',
    },
    tokenLocker: '0x7c0b97cd0fdc6b924129344502dd9de100a2f934',
    yieldBooster: '0x282fdb7A2876Ade5C027061D6FA5D7724AE1b2e5',
  },
  protocolToken: {
    address: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
    symbol: 'ARX',
  },
  xProtocolToken: {
    address: '0xa954A31137fBe5c2D384A0067DE042bAA58b3403',
    symbol: 'xARX',
  },
  mainPoolId: '',
  secondaryPoolId: '',

  masterChefContractAddress: '',
  defaultTokenIn: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  defaultTokenOut: '0xD5954c3084a1cCd70B4dA011E67760B8e78aeE84',
  farmTypeName: '',
  additionalLinks: [],
  priceImpact: {
    invest: {
      noticeable: 0.005,
      high: 0.01,
    },
    trade: {
      noticeable: 0.01,
      high: 0.05,
    },
    withdraw: {
      noticeable: 0.005,
      high: 0.01,
    },
  },
  merkl: {
    isSupported: true,
    distributorAddress: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
    // additionalRewardToken1: '0x23432452B720C80553458496D4D9d7C5003280d0',
    // additionalRewardToken1Symbol: 'FXS',
  },
  odos: {
    isOdosChain: true,
    referralCode: 2005541195,
    routerAddress: '0xa669e7A0d4b3e4Fa48af2dE86BD4CD7126Be4e13',
  },
  socialLinks: {
    twitter: 'https://twitter.com/arbidexfi',
    telegram: 'https://t.me/Abridexchat',
    // youtube: "https://www.youtube.com/@BaseSwap",
    medium: 'https://medium.com/@arbitrumexchange',
    discord: 'https://discord.gg/arbidex',
    documents: 'https://arbidex.gitbook.io/arbitrum-exchange/',
    // linktree: "https://linktr.ee/swapmodefi",
    // warpcast: "https://warpcast.com/swapmode",
  },
  locker: {
    lockingToken: 'ARX',
  },
};
