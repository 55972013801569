import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { isAddress } from 'ethers/lib/utils';
import { AddressZero } from '@ethersproject/constants';
import { useMultiCall } from '../util/useMultiCall';
import { TokenBase } from '../services/token/token-types';
import { GqlToken } from '~/apollo/generated/graphql-codegen-generated';
import ERC20Abi from '~/lib/abi/ERC20.json';
import { useWeb3React } from '~/hooks/useWeb3React';
import { TokenWithImportedFlag } from '../global/useToken';

interface TokenBaseWithChainId extends GqlToken {
  chainId: number;
  tradeable: boolean;
}

const USER_IMPORTED_CACHE_KEY = 'USER_IMPORTED_TOKENS';

const cached = typeof window !== 'undefined' ? localStorage.getItem(USER_IMPORTED_CACHE_KEY) : null;
const userImportedTokensVar = makeVar<TokenBaseWithChainId[]>(cached ? JSON.parse(cached) : []);

export function useUserImportedTokens() {
  const [addressToLoad, setAddressToLoad] = useState<string | null>(null);
  const [tokenToImport, setTokenToImport] = useState<TokenBaseWithChainId | null>(null);
  const address = addressToLoad || AddressZero;

  const { chainId } = useWeb3React();

  const { data, isError, isLoading } = useMultiCall({
    abi: ERC20Abi,
    calls: [
      { address, functionName: 'symbol' },
      { address, functionName: 'decimals' },
      { address, functionName: 'name' },
    ],
  });

  const dataItem = (data || [])[0];

  useEffect(() => {
    if (
      addressToLoad &&
      data &&
      tokenToImport?.address !== addressToLoad &&
      !getImportedToken(addressToLoad)
    ) {
      setTokenToImport({
        __typename: 'GqlToken',
        chainId,
        address: addressToLoad,
        symbol: data[0].result as string,
        decimals: data[1].result as number,
        name: data[2].result as string,
        tradeable: true,
        isStableCoin: false,
        isQuoteToken: false,
      });
    }
  }, [dataItem, addressToLoad, data, tokenToImport?.address, chainId]);

  useEffect(() => {
    setAddressToLoad(null);
  }, [isError]);

  function getImportedToken(address: string): TokenBase | null {
    const importedTokens = userImportedTokensVar();
    address = address.toLowerCase();

    return importedTokens.find((token) => token.address === address) || null;
  }

  function loadToken(address: string) {
    if (isAddress(address) && !getImportedToken(address)) {
      setAddressToLoad(address.toLowerCase());
    }
  }

  function clearTokenImport() {
    setAddressToLoad(null);
    setTokenToImport(null);
  }

  function importToken() {
    if (tokenToImport && !getImportedToken(tokenToImport.address)) {
      const updated = [...userImportedTokensVar(), { ...tokenToImport, chainId }];
      userImportedTokensVar(updated);
      localStorage.setItem(USER_IMPORTED_CACHE_KEY, JSON.stringify(updated));

      clearTokenImport();
    }
  }

  function removeToken(address: string) {
    const filtered = userImportedTokensVar().filter(
      (token) => token.address !== address.toLowerCase(),
    );

    userImportedTokensVar(filtered);
    localStorage.setItem(USER_IMPORTED_CACHE_KEY, JSON.stringify(filtered));
  }

  function removeAllUserImportedTokens() {
    userImportedTokensVar([]);
    localStorage.setItem(USER_IMPORTED_CACHE_KEY, JSON.stringify([]));
  }

  const userImportedTokens: TokenWithImportedFlag[] = useReactiveVar(userImportedTokensVar)
    .filter((token) => token.chainId === chainId)
    .map((token) => ({
      ...token,
      __typename: 'GqlToken',
      tradable: true,
      priority: 0,
      imported: true,
    }));

  return {
    getImportedToken,
    loadToken,
    removeToken,
    removeAllUserImportedTokens,
    userImportedTokens,
    isLoading,
    isError,
    tokenToImport,
    addressToLoad,
    clearTokenImport,
    importToken,
  };
}
